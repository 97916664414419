import { PurchasingRequisitionItemCommentsQueryParams } from '@/common/types/purchasing';
import { useQuery } from '@tanstack/react-query';
import {
  comparePurchasingRequisitionItems,
  ComparePurchasingRequisitionItemsPerams,
  getPurchasingRequisitionAccountBudgets,
  GetPurchasingRequisitionAccountBudgetsParams,
  getPurchasingRequisitionItemComments,
  getPurchasingRequisitionItemsSupplierTotals,
  GetPurchasingRequisitionItemsSupplierTotalsParams
} from './api';
import {
  PURCHASING_REQUISITION_ACCOUNT_BUDGETS,
  PURCHASING_REQUISITION_ITEM_COMMENTS,
  PURCHASING_REQUISITION_ITEMS_COMPARISON,
  PURCHASING_REQUISITION_ITEMS_SUPPLIER_TOTALS
} from './query-keys';
import queryClient from '@/utils/query';

/* Comments */
export const useGetPurchasingRequisitionItemComments = ({
  id
}: PurchasingRequisitionItemCommentsQueryParams) => {
  return useQuery({
    queryKey: [id, PURCHASING_REQUISITION_ITEM_COMMENTS],
    queryFn: () => getPurchasingRequisitionItemComments({ id }),
    enabled: !!id,
    retry: false
  });
};
/* -- */

/* Comparison */
export const useComparePurchasingRequisitionItems = (
  params: ComparePurchasingRequisitionItemsPerams & {
    enabled: boolean;
    requisition_item_id?: number;
    requisition_supplier_id?: number | string;
  }
) => {
  const { enabled, requisition_item_id, requisition_supplier_id, id, ...rest } = params;

  return useQuery({
    queryKey: [PURCHASING_REQUISITION_ITEMS_COMPARISON, id, rest],
    queryFn: () => comparePurchasingRequisitionItems({ id, ...rest }),
    enabled: false,
    retry: false,
    select: () => null
  });
};

export const recomparePurchasingRequisitionItems = (id: number) =>
  queryClient.invalidateQueries({ queryKey: [PURCHASING_REQUISITION_ITEMS_COMPARISON, id] });
/* -- */

/* Supplier Totals */
export const useGetPurchasingRequisitionItemsSupplierTotals = (
  params: GetPurchasingRequisitionItemsSupplierTotalsParams
) => {
  return useQuery({
    queryKey: [PURCHASING_REQUISITION_ITEMS_SUPPLIER_TOTALS, params],
    queryFn: () => getPurchasingRequisitionItemsSupplierTotals(params),
    enabled: false,
    retry: false
  });
};

export const recalculatePurchasingRequisitionItemsSupplierTotals = () =>
  queryClient.invalidateQueries({
    queryKey: [PURCHASING_REQUISITION_ITEMS_SUPPLIER_TOTALS]
  });
/* -- */

/* Account Bugdet */
export const useGetPurchasingRequisitionAccountBudgets = (
  params: GetPurchasingRequisitionAccountBudgetsParams
) => {
  const { id } = params;

  return useQuery({
    queryKey: [PURCHASING_REQUISITION_ACCOUNT_BUDGETS, id],
    queryFn: () => getPurchasingRequisitionAccountBudgets({ id }),
    enabled: false,
    retry: false
  });
};
/* -- */
